import React, { useEffect, useState } from "react";
import { fetchShowArticle } from "../../services/articles";

const useArticle = (params) => {
  const [article, setArticle] = useState();
  const [sponsor, setSponsor] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchArticleData = async () => {
      setLoading(true);

      try {
        const response = await fetchShowArticle(params);

        if (response && response?.data && response?.status === 200) {
          setArticle(response?.data?.article);
          setSponsor(response?.data?.sponsor);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setLoading(false);
      }
    };
    if (params?.id && article == undefined) fetchArticleData();
  }, [params?.id, article]);

  return {
    article,
    sponsor,
    loading,
  };
};

export default useArticle;
