import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below, up: above },
  } = theme;
  return {
    top_ads: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      rowGap: spacing(2),
      "& [class^='video_container']": {
        margin: "0px !important",
      },
      "& [class^='ads_container']": {
        margin: "0px !important",
      },
      [below(1025)]: {
        paddingTop: spacing(3),
        paddingBottom: spacing(3),
        flexDirection: "row",
        justifyContent: "space-evenly",
        maxWidth: "100% !important",
      },
      [below(values.sm)]: {
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        flexDirection: "column",
        alignItems: "center",
      },
    },
    width_layout_default: {
      [above(1025)]: {
        maxWidth: "400px !important",
      },
    },
    white_borders: {
      "& [class^='video_container']": {
        border: "1px solid white !important",
        [below(values.md)]: {
          margin: "0px !important",
        },
      },
      "& [class*='ad_for_bullseye']": {
        "& > div > iframe": {
          border: "1px solid white !important",
        },
      },
    },
  };
});

export { useStyles };
