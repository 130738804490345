import React from "react";
import Grid from "../../../common/Grid/Grid";
import { useStyles } from "./VerticalCard.style";
import PropTypes from "prop-types";
import VideoPlayer from "../../../common/VideoPlayer/VideoPlayer";
import Link from "../../../common/Link/Link";
import Typography from "../../../common/Typography/Typography";
import clsx from "clsx";
import { redirectToPage } from "../../../utils/helper";

const VerticalCard = ({ item, number, propClasses }) => {
  const classes = useStyles();
  const {
    name,
    url_content,
    media_content,
    section_for_front_end: section,
    column_for_front_end: column,
    category_for_front_end: category,
  } = item;
  return (
    <Grid container className={classes.vertical_card}>
      {number && (
        <div className={classes.number_container}>
          <Typography color="white" bold level="t5_text_5">
            {number}
          </Typography>
        </div>
      )}

      <Grid
        item
        xs={12}
        className={classes.media_container}
        onClick={() => redirectToPage(url_content, "_top")}
      >
        {media_content[0]?.isVideo ? (
          <VideoPlayer
            url={media_content[0]?.contentUrl}
            height="100%"
            width="100%"
          />
        ) : (
          <img
            className={classes.image}
            src={media_content[0]?.contentUrl}
            alt={media_content[0]?.alt_text || "most popular article image"}
          />
        )}
      </Grid>

      {(section || column || category) && (
        <Grid item xs={12}>
          <Link
            level="paragraph_2"
            bold
            url={section?.path || column?.path || category?.path}
          >
            {section?.name || column?.name || category?.name}
          </Link>
        </Grid>
      )}

      <Grid item xs={12}>
        <Link level="paragraph_2" url={url_content}>
          {name}
        </Link>
      </Grid>
    </Grid>
  );
};

VerticalCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    byLine: PropTypes.string,
    published_at: PropTypes.string,
    slug: PropTypes.string,
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        contentThumbUrl: PropTypes.string,
        contentCarrouselUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        title: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    url_content: PropTypes.string,
    section_for_front_end: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
    column_for_front_end: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
    category_for_front_end: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  }).isRequired,
  number: PropTypes.number,
};

export default VerticalCard;
