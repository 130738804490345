import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./HorizontalCard.style";
import Grid from "../../../common/Grid/Grid";
import Typography from "../../../common/Typography/Typography";
import VideoPlayer from "../../../common/VideoPlayer/VideoPlayer";
import Link from "../../../common/Link/Link";
import { redirectToPage } from "../../../utils/helper";

const HorizontalCard = ({ item, variant, number }) => {
  const classes = useStyles();
  const defaultClasses = {
    container: classes[`container_${variant}`],
    number_container: classes[`number_container_${variant}`],
    number: classes.number,
    media_content: classes[`media_content_${variant}`],
    information_container: classes[`information_container_${variant}`],
    article_title: classes[`article_title_${variant}`],
    section_container: classes.section_container,
    section: classes.section,
  };
  let spacing = 0;
  if (
    variant === "default" ||
    variant === "layout_2" ||
    variant === "layout_4"
  ) {
    spacing = 3;
  }

  return (
    <Grid container spacing={spacing} className={defaultClasses.container}>
      {number && (
        <Grid item className={defaultClasses.number_container}>
          <Typography level={"h4"} className={defaultClasses.number}>
            {number}
          </Typography>
        </Grid>
      )}
      <Grid item xs={4} className={defaultClasses.media_content}>
        {item.media_content[0].isVideo ? (
          <VideoPlayer
            url={item.media_content[0].contentUrl}
            width={"100%"}
            height={"100%"}
            showControls={false}
          />
        ) : (
          <img
            onClick={() => redirectToPage(item.url_content, "_top")}
            src={item.media_content[0].contentUrl}
            alt={item.media_content[0].alt_text}
          />
        )}
      </Grid>
      <Grid
        item
        xs={number ? 7 : 8}
        className={defaultClasses.information_container}
      >
        <Grid container>
          {variant !== "default" && (
            <Grid item xs={12} className={defaultClasses.section_container}>
              <Link
                level={"t4_text_4"}
                color={"tapintoOrange"}
                url={
                  item.section_for_front_end?.path ||
                  item.column_for_front_end?.path ||
                  item.category_for_front_end?.path
                }
                className={defaultClasses.section}
              >
                {item.section_for_front_end?.name ||
                  item.category_for_front_end?.name ||
                  item.column_for_front_end?.name}
              </Link>
            </Grid>
          )}

          <Grid item xs={12}>
            <Link
              level={"t4_text_4"}
              url={item.url_content}
              className={defaultClasses.article_title}
            >
              {item.name}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

HorizontalCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    byLine: PropTypes.string,
    published_at: PropTypes.string,
    slug: PropTypes.string,
    media_content: PropTypes.arrayOf(
      PropTypes.shape({
        contentUrl: PropTypes.string,
        contentThumbUrl: PropTypes.string,
        contentCarrouselUrl: PropTypes.string,
        credits: PropTypes.string,
        caption: PropTypes.string,
        alt_text: PropTypes.string,
        title: PropTypes.string,
        isVideo: PropTypes.bool,
      })
    ),
    url_content: PropTypes.string,
    section_for_front_end: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
    column_for_front_end: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
    category_for_front_end: PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  }).isRequired,
  variant: PropTypes.oneOf(["default", "layout_2", "layout_4"]),
  number: PropTypes.number,
};

export default HorizontalCard;
