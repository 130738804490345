import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values },
  } = theme;
  return {
    media_container: {
      cursor: "pointer",
      height: spacing(25),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.light.grey,
      [below(values.md)]: {
        height: spacing(29),
      },
    },
    number_container: {
      position: "absolute",
      top: 0,
      left: 0,
      backgroundColor: palette.dark.imageBackground,
      width: spacing(5),
      height: spacing(5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    vertical_card: {
      position: "relative",
      rowGap: spacing(1),
    },
    image: {
      maxHeight: "100%",
    },
  };
});

export { useStyles };
