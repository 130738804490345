import React, { useMemo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useStyles } from "./CustomSlide.style";
import Typography from "../../../Typography/Typography";
import VideoPlayer from "../../../VideoPlayer/VideoPlayer";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import labels from "../../../../config/labels";
import Download from "../../../Icons/Download";
import Button from "../../../Button/Button";
import theme from "../../../Theme/themes/default";
import { getTextFromHtml } from "../../../../utils/helper";
import values from "../../../Theme/themes/breakpoints/values";

const WIDTH_LIMIT_FOR_DOCUMENTS = 660;
const WHITE = "#000000";
const BLACK = "#FFFFFF";

const Item = ({
  contentUrl,
  caption,
  credits,
  alt_text,
  title,
  isVideo,
  onImageClick,
  className,
  videoPlayerProps,
  documentUrl,
  showArrows,
  darkBackground,
}) => {
  const classes = useStyles();
  const isMobile = screenWidthIsLowerThan(values.sm);
  const VideoPlayerHeight = useMemo(() => {
    if (isMobile) {
      return "240px";
    }
    return videoPlayerProps?.height || "100%";
  }, [isMobile, videoPlayerProps?.height]);

  const handleShowPdf = () => {
    window.open(documentUrl, "_blank");
  };

  const DocumentDownload = ({ fullSize }) => {
    return (
      <div
        className={clsx(
          fullSize ? classes.full_size : classes.show_pdf_container
        )}
      >
        <Button
          level={"simple_button"}
          onClick={handleShowPdf}
          color={WHITE}
          className={clsx(
            fullSize ? classes.full_pdf_button : classes.show_pdf_button
          )}
          variant={"contained"}
        >
          <Typography
            url={documentUrl}
            className={classes.download}
            level={"t4_text_4"}
            color={darkBackground ? "white" : BLACK}
          >
            {labels.SHOW_PDF}
          </Typography>
          <div className={classes.download_icon}>
            <Download width={24} height={18} fillColor={BLACK} />
          </div>
        </Button>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundColor: darkBackground
            ? theme.palette.dark.imageBackground
            : theme.palette.light.grey,
        }}
        className={clsx(
          classes.image_container,
          className && className,
          documentUrl ? classes.image_container_with_pdf : ""
        )}
      >
        {isVideo && (
          <VideoPlayer
            url={contentUrl}
            width="100%"
            showControls={true}
            height={VideoPlayerHeight}
          />
        )}
        {!isVideo && (
          <img
            src={contentUrl}
            title={title}
            alt={alt_text ? alt_text : ""}
            className={clsx(
              classes.image,
              onImageClick && classes.pointer_cursor
            )}
            {...(onImageClick && { onClick: onImageClick })}
          />
        )}
        {documentUrl && !screenWidthIsLowerThan(WIDTH_LIMIT_FOR_DOCUMENTS) && (
          <DocumentDownload fullSize={false} />
        )}
      </div>
      {showArrows && <div className={classes.arrows_container} />}
      {(caption || credits) && (
        <div className={classes.caption_credits}>
          {caption && (
            <Typography
              level="paragraph_1"
              color={darkBackground ? "white" : "black"}
            >
              {getTextFromHtml(caption)}
            </Typography>
          )}
          {credits && (
            <Typography
              level="paragraph_1"
              color={darkBackground ? "white" : "gray"}
            >
              {credits}
            </Typography>
          )}
        </div>
      )}
      {documentUrl && screenWidthIsLowerThan(WIDTH_LIMIT_FOR_DOCUMENTS) && (
        <DocumentDownload fullSize={true} />
      )}
    </>
  );
};

Item.propTypes = {
  contentUrl: PropTypes.string,
  caption: PropTypes.string,
  credits: PropTypes.string,
  alt_text: PropTypes.string,
  title: PropTypes.string,
  isVideo: PropTypes.bool,
  onImageClick: PropTypes.func,
  className: PropTypes.string,
  videoPlayerProps: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  documentUrl: PropTypes.string,
};

export default Item;
