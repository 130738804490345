import React from "react";
import Typography from "../../../../../common/Typography/Typography";
import labels from "../../../../../config/labels";
import { string } from "prop-types";

const EditorNotes = ({ editorNotes }) => {
  return (
    <Typography level="t2_text_2" italic>
      {labels.EDITORS_NOTES + editorNotes}
    </Typography>
  );
};

EditorNotes.propTypes = {
  editorNotes: string,
};

export default EditorNotes;
