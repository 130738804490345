import React from "react";
import { useStyles } from "./PromotionalFooter.style";
import { string } from "prop-types";

const PromotionalFooter = ({ promotionalFooter }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.promotional_footer}
      dangerouslySetInnerHTML={{
        __html: promotionalFooter,
      }}
    />
  );
};

PromotionalFooter.propTypes = {
  promotionalFooter: string,
};

export default PromotionalFooter;
