import React from "react";
import clsx from "clsx";
import { useStyles } from "./Leaderboard.style";
import Grid from "../../../../../common/Grid/Grid";
import Ad from "../../../../Ad/Ad";

const Leaderboard = ({ props, options }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      classes={{
        root: clsx(
          classes.ad_container,
          options?.marginBottom && classes.margin_bottom
        ),
      }}
    >
      <Ad {...props} />
    </Grid>
  );
};

Leaderboard.defaultProps = {
  options: { marginBottom: true },
};

export default Leaderboard;
