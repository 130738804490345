import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { down: below, values: values },
  } = theme;
  return {
    // default
    container_default: {
      borderTop: `1px solid ${palette.grayVariation.grayForBorder}`,
      rowGap: spacing(3),
      marginTop: spacing(5),
      padding: `${spacing(3)}px 0px`,
      [below(values.md)]: {
        marginTop: spacing(3),
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
      },
    },
    text_label_default: {
      fontWeight: 700,
      fontSize: "24px !important",
      letterSpacing: "1.2px",
    },

    // layout_1
    container_layout_1: {
      borderTop: `2px solid black`,
      padding: `${spacing(3)}px 0px`,
      rowGap: spacing(3),
      marginTop: spacing(4),
    },
    text_label_layout_1: {
      fontWeight: 700,
      fontSize: spacing(3),
      letterSpacing: "1.2px",
    },

    // layout_2
    container_layout_2: {
      padding: `${spacing(4)}px 0px`,
      rowGap: spacing(3),
      borderTop: `1px solid ${palette.grayVariation.grayForBorder}`,
      [below(1025)]: {
        borderBottom: `1px solid ${palette.grayVariation.grayForBorder}`,
      },
    },
    items_grid_layout_2: {
      rowGap: spacing(9),
      [below(values.md)]: {
        rowGap: spacing(5),
      },
    },
    text_label_layout_2: {
      color: palette.themeColor.primary,
      fontWeight: 700,
      fontSize: spacing(3),
      letterSpacing: "1.2px",
    },

    // layout_3
    container_layout_3: {
      padding: `${spacing(3)}px ${spacing(4)}px`,
      rowGap: spacing(3),
      borderTop: `1px solid ${palette.grayVariation.grayForBorder}`,
    },
    items_grid_layout_3: {
      rowGap: spacing(5),
    },
    text_label_layout_3: {
      fontWeight: 700,
      fontSize: spacing(3),
      letterSpacing: "1.2px",
    },

    // layout_4
    container_layout_4: {
      padding: `${spacing(4)}px 0px`,
      rowGap: spacing(3),
      borderTop: `1px solid ${palette.grayVariation.grayForBorder}`,
      [below(values.md)]: {
        padding: `${spacing(3)}px ${spacing(2)}px`,
        borderBottom: `1px solid ${palette.grayVariation.grayForBorder}`,
      },
    },
    items_grid_layout_4: {
      rowGap: spacing(5),
    },
    text_label_layout_4: {
      fontWeight: 700,
      fontSize: spacing(3),
      letterSpacing: "1.2px",
      color: palette.themeColor.primary,
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },

    // layout_5
    container_layout_5: {
      padding: `${spacing(4)}px 0px`,
      rowGap: spacing(3),
      borderTop: `1px solid ${palette.themeColor.primary}`,
      borderBottom: `1px solid ${palette.themeColor.primary}`,
      [below(values.md)]: {
        padding: `${spacing(3)}px ${spacing(2)}px`,
      },
    },
    text_label_layout_5: {
      fontWeight: 700,
      fontSize: spacing(3),
      letterSpacing: "1.2px",
      color: palette.themeColor.primary,
    },
    no_printing: {
      "@media print": {
        display: "none !important",
      },
    },
  };
});

export { useStyles };
