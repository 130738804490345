import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { down: below, values },
  } = theme;

  return {
    rounded_corners_images: {
      borderRadius: spacing(1),
      "&::before": {
        borderRadius: spacing(1),
        [below(values.sm)]: {
          borderRadius: 0,
        },
      },
      [below(values.sm)]: {
        borderRadius: 0,
      },
    },
    rounded_corners_videos: {
      "& iframe": {
        borderRadius: spacing(1),
        [below(values.sm)]: {
          borderRadius: 0,
        },
      },
    },
    jumbotron_container: {
      height: "100% !important",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(63, 63, 63, 0.65)",
      },
    },
    video_container: {
      height: "100% !important",
    },
    title: {
      "& strong": {
        lineHeight: "normal",
        [below(values.sm)]: {
          fontSize: "16px !important",
        },
      },
      "& h3": {
        padding: "0px !important",
        margin: "0px !important",
      },
    },
    left_container: {
      height: "100%",
      [below(1025)]: {
        height: "30%",
      },
      [below(values.sm)]: {
        height: "fit-content",
      },
    },
    right_container: {
      height: "100%",
      [below(1025)]: {
        height: "70%",
      },
      [below(values.sm)]: {
        height: "fit-content",
      },
    },
    left_items_container: {
      height: "100%",
      gap: spacing(2),
      display: "flex",
      flexDirection: "column",
      [below(1025)]: {
        flexDirection: "row",
      },
      [below(values.sm)]: {
        flexDirection: "column",
      },
    },
    small_right_items_container: {
      display: "flex",
      flexDirection: "row",
      gap: spacing(2),
      height: "100%",
      [below(values.sm)]: {
        flexDirection: "column",
      },
    },
    right_items_container: {
      height: "100%",
      gap: spacing(2),
      [below(values.sm)]: {
        flexDirection: "column",
      },
    },
    left_item: {
      height: "100%",
      width: "100%",
      [below(1025)]: {
        width: "50%",
      },
      [below(values.sm)]: {
        height: "210px",
        width: "100%",
      },
    },
    right_item_small: {
      width: "50%",
      height: "100%",
      [below(values.sm)]: {
        height: "210px",
        width: "100%",
      },
    },
    right_item_large: {
      width: "100%",
      height: "100%",
      [below(values.sm)]: {
        height: "210px",
      },
    },
    container: {
      height: "768px",
      width: "100% !important",
      margin: "0px !important",
      [below(1025)]: {
        height: "944px",
      },
      [below(values.sm)]: {
        height: "fit-content",
        gap: spacing(2),
      },
    },
    video_text_container: {
      backgroundColor: "rgba(63, 63, 63, 0.65)",
      padding: spacing(1),
    },
    text_container: {
      textOverflow: "ellipsis",
      display: "box",
      boxOrient: "vertical",
      overflow: "hidden",
      height: "fit-content",
      lineClamp: 4,
    },
    right_items_top_container: {
      height: "calc(40% - 8px)",
      [below(values.sm)]: {
        height: "100%",
      },
    },
    right_items_bottom_container: {
      height: "calc(60% - 8px)",
      [below(values.sm)]: {
        height: "100%",
      },
    },
  };
});

export { useStyles };
