import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./MostRead.style";
import Grid from "../../../common/Grid/Grid";
import VerticalCard from "../VerticalCard/VerticalCard";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import useMostReadArticles from "../../../hooks/ArticleShow/useMostReadArticles";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../../common/Theme/themes/breakpoints/values";
import clsx from "clsx";

const MostRead = ({ variant, articleProps }) => {
  const classes = useStyles();
  const { mostReadArticles, loading } = useMostReadArticles(articleProps);
  const largeScreen = screenWidthIsLowerThan(values.l);
  const mediumScreen = screenWidthIsLowerThan(values.md);
  const xSmallScreen = screenWidthIsLowerThan(values.xs);
  const smallScreen = screenWidthIsLowerThan(values.sm);
  const itemWidth = useMemo(() => {
    if (xSmallScreen) {
      return 12;
    }
    if (smallScreen || (variant === "default" && mediumScreen)) {
      return 8;
    }
    if (mediumScreen || (variant === "default" && largeScreen)) {
      return 6;
    }

    return 3;
  }, [smallScreen, xSmallScreen, mediumScreen, largeScreen]);

  const defaultClasses = {
    container_layout: classes[`container_${variant}`],
    text_label: classes[`text_label_${variant}`],
    items_grid: classes[`items_grid_${variant}`],
  };

  if (loading || mostReadArticles?.length < 4) {
    return <></>;
  }

  return (
    <Grid
      container
      className={clsx(defaultClasses.container_layout, classes.no_printing)}
    >
      <Grid item xs={12}>
        <Typography level="h2" className={defaultClasses.text_label}>
          {labels.MOST_READ}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          className={defaultClasses.items_grid}
          justifyContent={itemWidth === 8 ? "center" : "flex-start"}
        >
          {mostReadArticles?.map((item, index) => (
            <Grid item xs={itemWidth} key={index}>
              <VerticalCard item={item} number={index + 1} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

MostRead.propTypes = {
  variant: PropTypes.oneOf([
    "default",
    "layout_1",
    "layout_2",
    "layout_3",
    "layout_4",
    "layout_5",
  ]),
};

export default MostRead;
