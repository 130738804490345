import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    palette,
    spacing,
    breakpoints: { down: below, values },
  } = theme;
  return {
    promotional_footer: {
      fontFamily: "Montserrat",
      fontSize: "17px",
      fontWeight: 400,
      lineHeight: 1.6,
      textRendering: "optimizeLegibility",
      marginBottom: spacing(2),
      "& a": {
        color: palette.miscellaneous.blueLink,
      },
      [below(1139)]: {
        paddingLeft: spacing(2),
      },
      [below(values.md)]: {
        paddingLeft: 0,
      },
    },
  };
});

export { useStyles };
