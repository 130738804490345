import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below },
  } = theme;
  return {
    top_section: {
      backgroundColor: "black",
    },
    breadcrumbs_container: {
      marginTop: `${spacing(5)}px !important`,
      borderBottom: "1px solid #EFEFEF",
      marginBottom: `${spacing(4)}px !important`,
      marginLeft: `${spacing(3)}px !important`,
      marginRight: `${spacing(3)}px !important`,
    },
    edit_container: {
      marginBottom: `${spacing(2)}px !important`,
      padding: `0px ${spacing(3)}px !important`,
    },
    sponsor_container: {
      display: "flex",
      justifyContent: "flex-end",
      padding: `0px ${spacing(3)}px !important`,
      "& [class^='sponsor_container']": {
        width: "fit-content !important",
        display: "flex",
        columnGap: spacing(2),
        "& > span": {
          color: "white !important",
        },
      },
    },
    section_tag_container: {
      textAlign: "center",
      marginBottom: `${spacing(2)}px !important`,
    },
    header_container: {
      textAlign: "center",
      marginBottom: `${spacing(2)}px !important`,
    },
    subheader_container: {
      textAlign: "center",
    },
    sponsored_content_container: {
      textAlign: "right",
      marginBottom: `${spacing(2)}px !important`,
      padding: `0px ${spacing(3)}px !important`,
    },
    header: {
      fontSize: "40px !important",
      letterSpacing: "0.8px !important",
      lineHeight: "normal",
      [below(values.md)]: {
        fontSize: "24px !important",
      },
    },
    subheader: {
      fontSize: "28px !important",
      lineHeight: "normal",
      [below(values.md)]: {
        fontSize: "18px !important",
      },
    },
    carousel_and_ads_container: {
      marginTop: `${spacing(8)}px !important`,
      marginBottom: `${spacing(5)}px !important`,
      padding: `0px ${spacing(3)}px !important`,
      [below(values.md)]: {
        padding: `0px !important`,
      },
    },
    row_gap: {
      [below(values.md)]: {
        rowGap: `${spacing(6)}px !important`,
      },
    },
    body_container: {
      marginTop: `${spacing(3)}px !important`,
      [below(values.md)]: {
        padding: `0px ${spacing(2)}px !important`,
      },
    },
    preview_top_padding: {
      paddingTop: `${spacing(5)}px !important`,
    },
    audio_player_container: {
      padding: `${spacing(2)}px !important`,
      "@media print": {
        display: "none",
      },
    },
    article_container: {
      paddingBottom: spacing(2),
    },
  };
});

export { useStyles };
