import React from "react";
import { object } from "prop-types";
import Default from "../LayoutsByVariant/Default/Default";
import useArticle from "../../../hooks/ArticleShow/useArticle";
import Layout1 from "../LayoutsByVariant/Layout1/Layout1";
import Layout2 from "../LayoutsByVariant/Layout2/Layout2";
import Layout3 from "../LayoutsByVariant/Layout3/Layout3";
import Layout4 from "../LayoutsByVariant/Layout4/Layout4";
import Layout5 from "../LayoutsByVariant/Layout5/Layout5";

const Layouts = ({ props }) => {
  const {
    id,
    town_id,
    section_id,
    category_id,
    column_id,
    article_for_preview,
  } = props;
  const idPresent = Boolean(id);
  const { article, sponsor, loading } = idPresent
    ? useArticle({ id, town_id, section_id, category_id, column_id })
    : {};

  if (
    loading ||
    (idPresent && article === undefined) ||
    (!idPresent && article_for_preview === undefined)
  ) {
    return <></>;
  }

  switch (
    article?.current_layout_name ||
    article_for_preview?.current_layout_name
  ) {
    case "layout_1":
      return (
        <Layout1
          props={props}
          article={article ?? article_for_preview}
          sponsor={sponsor}
        />
      );
    case "layout_2":
      return (
        <Layout2
          article={article ?? article_for_preview}
          props={props}
          sponsor={sponsor}
        />
      );
    case "layout_3":
      return (
        <Layout3
          props={props}
          article={article ?? article_for_preview}
          sponsor={sponsor}
        />
      );
    case "layout_4":
      return (
        <Layout4
          article={article ?? article_for_preview}
          props={props}
          sponsor={sponsor}
        />
      );
    case "layout_5":
      return (
        <Layout5
          props={props}
          article={article ?? article_for_preview}
          sponsor={sponsor}
        />
      );
    default:
      return (
        <Default
          props={props}
          article={article ?? article_for_preview}
          sponsor={sponsor}
        />
      );
  }
};

Layouts.propTypes = {
  props: object.isRequired,
};

export default Layouts;
