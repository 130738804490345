import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./ByLine.style";
import Typography from "../../../common/Typography/Typography";
import EmailIcon from "../../../common/Icons/EmailIcon";
import Grid from "../../../common/Grid/Grid";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import Link from "../../../common/Link/Link";
import labels from "../../../config/labels";
import SocialNetworkShareButtons from "../../SocialNetworkShareButtons/SocialNetworkShareButtons";
import Box from "../../../common/Box/Box";
import clsx from "clsx";

const ByLine = ({
  title,
  url,
  author,
  author_email,
  published_at,
  updated_at,
  town_admins_profile_page_path,
  withDefault,
  preview,
}) => {
  const classes = useStyles();
  const mediumScreen = screenWidthIsLowerThan(1025);

  const printAutorLabel = () => {
    if (author && author !== "") {
      return author;
    }
    if (author_email && author_email !== "") {
      return author_email;
    }
    return null;
  };

  const AuthorLabel = () => {
    if (town_admins_profile_page_path !== "") {
      return (
        <Link
          url={town_admins_profile_page_path}
          level="paragraph_1"
          bold
          underlined
          className={classes.author}
          children={labels.ONE_SPACE + labels.BY + printAutorLabel()}
          target="_blank"
          rel="noopener noreferrer"
        />
      );
    }
    return (
      <Typography
        level="paragraph_1"
        bold
        underlined
        className={classes.author}
      >
        {labels.ONE_SPACE}
        {labels.BY}
        {printAutorLabel()}
      </Typography>
    );
  };

  return (
    <Box
      classes={{
        root: clsx(
          classes.byline_container,
          withDefault && classes.byline_container_default
        ),
      }}
    >
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {printAutorLabel() && <AuthorLabel />}
            {author_email && (
              <Link url={`mailto:${author_email}`}>
                <EmailIcon className={classes.mail_icon} fontSize="small" />
              </Link>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography level="paragraph_2" color="gray">
              {published_at}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography level="paragraph_2" color="gray">
              {updated_at}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {!preview && (
        <Box className={classes.no_printing}>
          <SocialNetworkShareButtons
            paddingWithinButtons={8}
            alignment={
              mediumScreen ? (withDefault ? "left" : "center") : "right"
            }
            iconsSize={32}
            socialNetworks={["facebook", "twitter", "email", "print"]}
            radius={0}
            spacing={16}
            defaultContainerClass={false}
            url={url}
            title={title}
          />
        </Box>
      )}
    </Box>
  );
};

ByLine.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  author: PropTypes.string,
  author_email: PropTypes.string,
  published_at: PropTypes.string,
  updated_at: PropTypes.string,
  town_admins_profile_page_path: PropTypes.string,
  withDefault: PropTypes.bool,
  preview: PropTypes.bool,
};

export default ByLine;
