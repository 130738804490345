import React from "react";
import clsx from "clsx";
import Grid from "../../../common/Grid/Grid";
import { useStyles } from "./ArticleGallery.style";
import Jumbotron from "../../../common/Jumbotron/Jumbotron";
import Typography from "../../../common/Typography/Typography";
import Link from "../../../common/Link/Link";
import { screenWidthIsLowerThan } from "../../../hooks/useMediaQuery";
import values from "../../../common/Theme/themes/breakpoints/values";
import { arrayOf, bool, object } from "prop-types";

const Item = ({ item, roundedCorners }) => {
  const { media_content: media, name, url_content: url } = item || {};
  const classes = useStyles();
  return (
    <Jumbotron
      {...(media[0]?.isVideo
        ? {
            variant: "video",
            videoUrl: media[0]?.contentUrl,
            classes: {
              container: {
                root: clsx(
                  classes.video_container,
                  roundedCorners && classes.rounded_corners_videos
                ),
              },
            },
          }
        : {
            variant: "image",
            backgroundImageUrl: media[0]?.contentUrl,
            classes: {
              container: {
                root: clsx(
                  classes.jumbotron_container,
                  roundedCorners && classes.rounded_corners_images
                ),
              },
            },
          })}
      contents={
        <div
          className={clsx(
            classes.text_container,
            media[0]?.isVideo && classes.video_text_container
          )}
        >
          <Link
            level="h3_sub_header"
            color="white"
            bold
            className={classes.title}
            url={url}
          >
            {name}
          </Link>
        </div>
      }
      options={{
        contentsType: "text",
        redirectTo: url,
      }}
    />
  );
};

Item.propTypes = {
  item: object,
  roundedCorners: bool,
};

const ArticleGallery = ({ items, roundedCorners }) => {
  const mediumScreen = screenWidthIsLowerThan(1025);
  const smallScreen = screenWidthIsLowerThan(values.sm);
  const classes = useStyles();

  return (
    <Grid container spacing={smallScreen ? 0 : 2} className={classes.container}>
      <Grid
        item
        xs={12}
        md={mediumScreen ? 12 : 6}
        className={classes.left_container}
      >
        <div className={classes.left_items_container}>
          <div className={classes.left_item}>
            <Item item={items[0]} roundedCorners={roundedCorners} />
          </div>
          <div className={classes.left_item}>
            <Item item={items[1]} roundedCorners={roundedCorners} />
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={mediumScreen ? 12 : 6}
        className={classes.right_container}
      >
        <Grid container className={classes.right_items_container}>
          <Grid item xs={12} className={classes.right_items_top_container}>
            <div className={classes.small_right_items_container}>
              <div className={classes.right_item_small}>
                <Item item={items[2]} roundedCorners={roundedCorners} />
              </div>

              <div className={classes.right_item_small}>
                <Item item={items[3]} roundedCorners={roundedCorners} />
              </div>
            </div>
          </Grid>

          <Grid item xs={12} className={classes.right_items_bottom_container}>
            <div className={classes.right_item_large}>
              <Item item={items[4]} roundedCorners={roundedCorners} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ArticleGallery.defaultProps = {
  roundedCorners: true,
};

ArticleGallery.propTypes = {
  items: arrayOf(object),
  roundedCorners: bool,
};

export default ArticleGallery;
