import { makeStyles } from "../../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below },
    palette,
  } = theme;
  return {
    ad_container: {
      display: "flex",
      justifyContent: "center",
      padding: `${spacing(5)}px 0px !important`,
      backgroundColor: palette.grayVariation.grayForContainerBackground,
      "& [class^='ads_container']": {
        marginBottom: "0px !important",
      },
      [below(values.md)]: {
        padding: `${spacing(4)}px 0px !important`,
      },
    },
    margin_bottom: {
      marginBottom: `${spacing(4)}px !important`,
    },
  };
});

export { useStyles };
