import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./SignUpNewsletter.styles";
import Typography from "../../common/Typography/Typography";
import Button from "../../common/Button/Button";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import TextInput from "../../common/TextInput/TextInput";
import clsx from "clsx";
import { subscribeTo } from "../../services/subscriber";
import labels from "../../config/labels";
import {
  screenWidthIsHigherThan,
  screenWidthIsLowerThan,
} from "../../hooks/useMediaQuery";
import { isValidEmail } from "../../utils/helper";
import SignUpBottomText from "./SignUpBottomText/SignUpBottomText";
import { loadThemeTexts } from "../../services/townService";
import AlreadySubscribedMessage from "./AlreadySubscribedMessage/AlreadySubscribedMessage";

const OK = 200;
const ACTION = "articleSignUp";

const SignUpComponent = ({
  subscribeToContent,
  isArticlePreview,
  isTown,
  isPremium,
  isArticle,
}) => {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState();
  const [largeScreen] = useState(screenWidthIsHigherThan(601));
  const [smallScreen] = useState(screenWidthIsLowerThan(602));
  const [inputError, setInputError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleCommitAction = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const result = executeRecaptcha(ACTION);

    setToken(result);

    // Enable this when recaptcha validation is moved away from Mongo project
    // https://senirop.atlassian.net/browse/TAP-648
    // const response = await validateRecaptcha(token);

    //Input validation
    if (!subscriberEmail) {
      setIsEmpty(true);
    } else if (!isValidEmail(subscriberEmail)) {
      setInputError(true);
      setIsEmpty(false);
    } else if (subscriberEmail && isValidEmail(subscriberEmail)) {
      setIsEmpty(false);
      setButtonDisabled(true);

      const subscribeResponse = await subscribeTo(
        subscribeToContent.type,
        subscribeToContent.slug,
        subscriberEmail,
        token,
        "content-signup"
      );

      if (subscribeResponse?.status === 200) {
        handleSuccess();
      }

      // status 204 means that email is already subscribed to town
      if (subscribeResponse?.status === 204) {
        setSuccessMessage(
          <AlreadySubscribedMessage
            slug={subscribeToContent.slug}
            email={subscriberEmail}
            linkColor={"white"}
          />
        );
      }
    }
  }, [executeRecaptcha, subscriberEmail]);

  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  const addContentName = (label) => {
    return label.replace("##contentName", subscribeToContent.name);
  };

  const handleSuccess = async () => {
    const customizedTextResponse = await loadThemeTexts(
      subscribeToContent.slug
    );

    if (
      customizedTextResponse?.status === 200 &&
      customizedTextResponse?.data?.post_subscription_message
    ) {
      setSuccessMessage(
        <div
          className={classes.custom_success_message}
          dangerouslySetInnerHTML={{
            __html: customizedTextResponse?.data?.post_subscription_message,
          }}
        />
      );
    } else {
      setSuccessMessage(addContentName(labels.YOU_HAVE_SUCCESSFULLY_SIGNED_UP));
    }
  };

  const ErrorMessage = () => {
    return (
      <div className={classes.error_container}>
        <Typography className={classes.error_message} level="paragraph_2" bold>
          {inputError ? labels.INPUT_ERROR : labels.SIGNUP_ERROR}
        </Typography>
      </div>
    );
  };

  const SignupButton = () => {
    return (
      <div
        className={
          successMessage ? classes.hidden : classes.container_submit_button
        }
      >
        <Button
          level="simple_button"
          color="primary"
          bold
          onClick={handleCommitAction}
          disabled={buttonDisabled}
          id="newsletter-subscription-article-button"
        >
          {labels.SUBSCRIBE}
        </Button>
      </div>
    );
  };

  const showSignUpBox = () => {
    return !isArticlePreview && (isTown || isPremium);
  };

  return (
    <>
      {showSignUpBox() && (
        <>
          {!successMessage && (
            <>
              <div className={clsx(classes.sign_up_container, isArticle && classes.sign_up_container_article)}>
                <div
                  className={clsx(classes.row, classes.font_title_sub_mobile)}
                >
                  <Typography level="paragraph_2" bold>
                    {addContentName(labels.SIGNUP_TITLE)}
                  </Typography>
                </div>
                <div
                  className={clsx(
                    classes.row,
                    classes.subtitle,
                    classes.font_title_sub_mobile
                  )}
                >
                  <Typography level="paragraph_2">
                    {labels.SIGNUP_SUBTITLE}
                  </Typography>
                </div>

                <div className={clsx(classes.row, classes.subscribe_form)}>
                  <div
                    className={clsx(
                      !isEmpty ? classes.email : classes.red_input_border
                    )}
                  >
                    <TextInput
                      value={subscriberEmail}
                      onChange={(event) =>
                        setSubscriberEmail(event.target.value)
                      }
                      placeholder={labels.EMAIL_PLACEHOLDER}
                      name="email"
                      type="email"
                    />
                  </div>
                  {largeScreen && <SignupButton />}
                </div>
                <SignUpBottomText town={subscribeToContent} />
                {smallScreen && <SignupButton />}
              </div>
              {inputError && <ErrorMessage />}
            </>
          )}
          {successMessage && (
            <>
              <div className={classes.success_container}>
                <div className={classes.row}>
                  {largeScreen && <SignupButton />}
                  {smallScreen && <SignupButton />}
                </div>
                <div className={classes.row}>
                  <Typography
                    className={classes.success_message}
                    level="paragraph_2"
                    color="white"
                    bold
                  >
                    {successMessage}
                  </Typography>
                </div>
              </div>
            </>
          )}

          <GoogleReCaptcha action={ACTION} onVerify={handleReCaptchaVerify} />
        </>
      )}
    </>
  );
};

SignUpComponent.propTypes = {
  subscribeToContent: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.number,
  }),
  isArticlePreview: PropTypes.bool,
  isPremium: PropTypes.bool,
  isArticle: PropTypes.bool,
};

export default SignUpComponent;
