import { useEffect, useState } from "react";
import { fetchRelatedNews } from "../../services/articles";


const useRelatedNews = ({
  id,
  section_id,
  column_id,
  town_id,
  category_id,
}) => {
  const [relatedNews, setRelatedNews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getRelatedNews = async () => {
      try {
        setLoading(true);
        const response = await fetchRelatedNews({
          id,
          section_id,
          column_id,
          town_id,
          category_id,
        });
        if (response && response?.status === 200 && response?.data.length > 0) {
          setRelatedNews(response.data);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setLoading(false);
      }
    };
    getRelatedNews();
  }, [id, section_id, column_id, town_id, category_id]);

  return {
    relatedNews,
    loading,
  };
};

export default useRelatedNews;
