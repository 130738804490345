import { useEffect, useState } from "react";
import { fetchMostReadArticles } from "../../services/articles";

const useMostReadArticles = ({
  id,
  town_id,
  category_id,
  column_id,
  section_id,
}) => {
  const [mostReadArticles, setMostReadArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getMostReadArticles = async () => {
      try {
        setLoading(true);
        const response = await fetchMostReadArticles({
          id,
          town_id,
          category_id,
          column_id,
          section_id,
        });
        if (response && response?.data.length > 0 && response?.status === 200) {
          setMostReadArticles(response.data);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setLoading(false);
      }
    };
    if (mostReadArticles.length === 0) {
      getMostReadArticles();
    }
  }, [id, town_id, category_id, column_id, section_id]);

  return { mostReadArticles, loading };
};

export default useMostReadArticles;
