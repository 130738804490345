import { makeStyles } from "../../../common/Theme/Theme";
import values from "../../../common/Theme/themes/breakpoints/values";

const useStyles = makeStyles((theme) => ({
  mail_icon: {
    color: theme.palette.themeColor.primary,
    verticalAlign: "middle",
    paddingLeft: theme.spacing(0.5),
    display: "none !important",
  },
  author: {
    fontWeight: 700,
    lineHeight: "32px",
  },
  byline_container: {
    marginBottom: theme.spacing(6),
    rowGap: theme.spacing(2),
    display: "grid",
    gridTemplateColumns: "1fr 0.6fr",
    [theme.breakpoints.down(values.md)]: {
      gridTemplateColumns: "1fr",
      rowGap: theme.spacing(2),
      textAlign: "center",
    },
  },
  byline_container_default: {
    [theme.breakpoints.down(values.md)]: {
      textAlign: "left",
    },
  },
  no_printing: {
    "@media print": {
      display: "none",
    },
  },
}));

export { useStyles };
