import React, { useEffect } from "react";
import { useStyles } from "./ArticleShow.style";
import Masterhead from "../../../components/Masterhead/Masterhead";
import Footer from "../../../components/Footer/Footer";
import Layouts from "../../../components/Article/Layouts/Layouts";

const ArticleShow = (props) => {
  const {
    master_head_info,
    town_info,
  } = props || {};
  const classes = useStyles();

  return (
    <>
      <div className={classes.master_head_container}>
        <Masterhead {...master_head_info} />
      </div>
      <Layouts props={props} />
      <Footer town={town_info} options={{ noMarginTop: true }} />
    </>
  );
};

export default ArticleShow;
