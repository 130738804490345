import React from "react";
import { object } from "prop-types";
import WithAds from "./WithAds/WithAds";
import AdFree from "./AdFree/AdFree";

const Layout4 = ({ props, article, sponsor }) => {
  if (article.ad_free) {
    return <AdFree props={props} article={article} sponsor={sponsor} />;
  }

  return <WithAds props={props} article={article} sponsor={sponsor} />;
};

Layout4.propTypes = {
  props: object,
  article: object,
  sponsor: object,
};

export default Layout4;
