import React from "react";
import { oneOf, string } from "prop-types";
import { useStyles } from "./SectionTag.style";
import Link from "../../../common/Link/Link";
import withConfig from "../../../utils/withConfig";

const SectionTag = ({ label, url, variant }) => {
  const classes = useStyles();
  const defaultClasses = {
    container: classes[`container_${variant}`],
    link: classes[`link_${variant}`],
  };

  return (
    <div className={defaultClasses.container}>
      <Link
        url={url || withConfig("HOME")}
        level="paragraph_2"
        children={label}
        className={defaultClasses.link}
      />
    </div>
  );
};

SectionTag.propTypes = {
  label: string,
  url: string,
  variant: oneOf(["layout_1", "layout_2_or_4", "layout_3", "layout_5"]),
};

export default SectionTag;
