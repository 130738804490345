import React from "react";

const ChevronLeft = ({ fillColor }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7914 3L7.43285 12.3586C7.35474 12.4367 7.35474 12.5633 7.43285 12.6414L16.7914 22"
        stroke={fillColor}
        strokeLinecap="round"
      />
    </svg>
  );
};

ChevronLeft.defaultProps = {
  fillColor: "black",
};

export default ChevronLeft;
