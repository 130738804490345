import React, { useMemo } from "react";
import clsx from "clsx";
import Grid from "../../../../common/Grid/Grid";
import { useStyles } from "./Layout2.style";
import Typography from "../../../../common/Typography/Typography";
import SectionTag from "../../SectionTag/SectionTag";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import ArticlesCarousel from "../../../../common/Carousel/Articles/ArticlesCarousel";
import Sponsor from "../../../Sponsor/Sponsor";
import TopAds from "../Components/TopAds/TopAds";
import Leaderboard from "../Components/Leaderboard/Leaderboard";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import values from "../../../../common/Theme/themes/breakpoints/values";
import AudioPlayer from "../../../AudioPlayer/AudioPlayer";
import ByLine from "../../ByLine/ByLine";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ArticleBody from "../../ArticleBody";
import PromotionalFooter from "../Components/PromotionalFooter/PromotionalFooter";
import EditorNotes from "../Components/EditorNotes/EditorNotes";
import Disclaimer from "../../Disclaimer/Disclaimer";
import RelatedNews from "../../RelatedNews/RelatedNews";
import withConfig from "../../../../utils/withConfig";
import MostRead from "../../MostRead/MostRead";
import { object } from "prop-types";
import StickySocialNetworkShareButtons from "../../../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import labels from "../../../../config/labels";
import Edit from "../../../Actions/Edit";
import { HEADER_CONTAINER_SELECTOR } from "../../../../utils/constants/trinityAudioConstants";

const Layout2 = ({ props, article, sponsor }) => {
  const classes = useStyles();

  const {
    article_body,
    category_name,
    subscribe_to_content,
    is_article_preview,
    is_town,
    is_premium,
    ad_targets,
    article_disclaimer,
    disclaimer_policy_page_links,
    town_editor_email,
    video_ad_content,
    breadcrumbs,
    id,
    section_id,
    column_id,
    town_id,
    category_id,
    bullseye_ad_count,
    can_edit,
    edit_url,
  } = props || {};

  const {
    name,
    url_content: url,
    subheading,
    column_for_front_end: column,
    opinions: article_column_category,
    is_advertisement,
    promotional_footer,
    ad_free,
    disclaimer,
    submitted_via,
    has_audio_player,
    byline,
    humanized_published_at: published_at,
    humanized_updated_at: important_data_changed_at,
    get_town_admin_profile_path: town_admins_profile_page_path,
    media_content,
    current_layout_name,
    section_for_front_end,
    category_for_front_end,
  } = article;

  const isMobile = screenWidthIsLowerThan(values.sm);
  const largeScreen = screenWidthIsLowerThan(1025);
  const leaderboardProps = useMemo(() => {
    if (isMobile) {
      return {
        top: { ad_unit: "leaderboard_mobile", size: [320, 100] },
        body: { ad_unit: "leaderboard_mobile_1", size: [320, 100] },
      };
    }
    return {
      top: { ad_unit: "tap_leaderboard_001", size: [728, 90] },
      body: { ad_unit: "tap_leaderboard_002", size: [728, 90] },
    };
  }, [isMobile]);

  return (
    <Grid container spacing={largeScreen ? 2 : 0} justifyContent="center">
      {!ad_free && (
        <Leaderboard
          props={{ ...leaderboardProps.top, targets: ad_targets }}
          options={{ marginBottom: false }}
        />
      )}

      <Grid item xs={12}>
        <Grid container classes={{ root: classes.main_container }}>
          {breadcrumbs?.length > 0 && (
            <Grid
              item
              xs={12}
              classes={{ root: classes.breadcrumbs_container }}
            >
              <Breadcrumbs items={breadcrumbs} color="white" />
            </Grid>
          )}

          {can_edit && (
            <Grid item xs={12} classes={{ root: classes.edit_container }}>
              <Edit editUrl={edit_url} />
            </Grid>
          )}

          {is_advertisement && (
            <Grid
              item
              xs={12}
              classes={{ root: classes.sponsored_content_container }}
            >
              <Typography level="paragraph_1" color="white">
                {labels.SPONSORED_CONTENT}
              </Typography>
            </Grid>
          )}

          {sponsor && (
            <Grid item xs={12} classes={{ root: classes.sponsor_container }}>
              <Sponsor sponsor_info={sponsor} />
            </Grid>
          )}

          {(section_for_front_end?.name ||
            category_for_front_end?.name ||
            column?.name) && (
            <Grid
              item
              xs={10}
              md={12}
              classes={{ root: classes.tag_container }}
            >
              <SectionTag
                variant="layout_2_or_4"
                label={
                  section_for_front_end?.name ||
                  category_for_front_end?.name ||
                  column?.name
                }
                url={
                  section_for_front_end?.path ||
                  category_for_front_end?.path ||
                  column?.path
                }
              />
            </Grid>
          )}

          <Grid item xs={12} className={classes.header_container}>
            <Typography
              bold
              color="white"
              level="h1_header_1"
              className={clsx(classes.header, HEADER_CONTAINER_SELECTOR)}
            >
              {name}
            </Typography>
          </Grid>

          {subheading && (
            <Grid item xs={12}>
              <Typography color="white" className={classes.subheader}>
                {subheading}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} classes={{ root: classes.top_section }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={ad_free || largeScreen ? 12 : 8}>
                <ArticlesCarousel items={media_content} darkBackground />
              </Grid>

              {!ad_free && !largeScreen && (
                <TopAds
                  video_ad_content={video_ad_content}
                  bullseye_ad_count={bullseye_ad_count}
                  ad_targets={ad_targets}
                  withWhiteBorders
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!ad_free && largeScreen && (
        <TopAds
          video_ad_content={video_ad_content}
          bullseye_ad_count={bullseye_ad_count}
          ad_targets={ad_targets}
        />
      )}

      {!ad_free && (
        <Leaderboard
          props={{ ...leaderboardProps.body, targets: ad_targets }}
          options={{ marginBottom: false }}
        />
      )}

      <Grid
        item
        xs={12}
        md={11}
        classes={{
          root: classes.contents_container,
        }}
      >
        <Grid container spacing={5} classes={{ root: classes.body_container }}>
          <Grid
            item
            xs={12}
            md={largeScreen || is_article_preview ? 12 : 8}
            classes={{
              root: classes.article_container,
            }}
          >
            <Grid container>
              {has_audio_player && (
                <Grid item xs={12} classes={{ root: classes.audio_container }}>
                  <AudioPlayer />
                </Grid>
              )}

              <Grid item xs={12}>
                <ByLine
                  author={byline}
                  published_at={published_at}
                  updated_at={important_data_changed_at}
                  author_email={town_editor_email}
                  town_admins_profile_page_path={town_admins_profile_page_path}
                  preview={is_article_preview}
                  title={name}
                  url={url}
                />
              </Grid>

              {!is_article_preview && (
                <StickySocialNetworkShareButtons top={window.innerHeight / 3} />
              )}

              <Grid item xs={12}>
                <GoogleReCaptchaProvider
                  useRecaptchaNet
                  reCaptchaKey={withConfig("RECAPTCHA_KEY")}
                  scriptProps={{
                    async: true,
                    defer: true,
                    appendTo: "body",
                  }}
                >
                  <ArticleBody
                    text={article_body}
                    column={column}
                    isAdvertisement={is_advertisement}
                    articleColumnCategory={article_column_category}
                    isAdFree={ad_free}
                    categoryName={category_name}
                    subscribeToContent={subscribe_to_content}
                    isArticlePreview={is_article_preview}
                    isTown={is_town}
                    isPremium={is_premium}
                    adTargets={ad_targets}
                  />
                </GoogleReCaptchaProvider>
              </Grid>

              {promotional_footer && (
                <Grid item xs={12}>
                  <PromotionalFooter promotionalFooter={promotional_footer} />
                </Grid>
              )}

              {disclaimer && (
                <Grid item xs={12}>
                  <EditorNotes editorNotes={disclaimer} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Disclaimer
                  categoryName={category_name}
                  column={column}
                  isAdvertisement={is_advertisement}
                  articleColumnCategory={article_column_category}
                  submittedVia={submitted_via}
                  disclaimerPolicyPageLinks={disclaimer_policy_page_links}
                  customDisclaimer={article_disclaimer}
                />
              </Grid>
            </Grid>
          </Grid>

          {!is_article_preview && !largeScreen && (
            <Grid item xs={12} md={largeScreen ? 12 : 4}>
              <RelatedNews
                articleProps={{
                  id,
                  section_id,
                  column_id,
                  town_id,
                  category_id,
                }}
                variant={current_layout_name}
              />
            </Grid>
          )}

          {!is_article_preview && (
            <Grid item xs={12} classes={{ root: classes.contents_container }}>
              <MostRead
                articleProps={{
                  id,
                  section_id,
                  column_id,
                  town_id,
                  category_id,
                }}
                variant={current_layout_name}
              />
            </Grid>
          )}

          {!is_article_preview && largeScreen && (
            <Grid item xs={12}>
              <RelatedNews
                articleProps={{
                  id,
                  section_id,
                  column_id,
                  town_id,
                  category_id,
                }}
                variant={current_layout_name}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

Layout2.propTypes = {
  props: object,
  article: object,
  sponsor: object,
};

export default Layout2;
