import React from "react";

const ChevronRight = ({ fillColor }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3741 22L16.7327 12.6414C16.8108 12.5633 16.8108 12.4367 16.7327 12.3586L7.3741 3"
        stroke={fillColor}
        strokeLinecap="round"
      />
    </svg>
  );
};

ChevronRight.defaultProps = {
  fillColor: "black",
};

export default ChevronRight;
