import { makeStyles } from "../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below },
  } = theme;

  const defaultStyle = {
    link: {
      textTransform: "uppercase",
      letterSpacing: "3.84px",
      fontWeight: "600 !important",
    },
  };
  return {
    // Layout 1
    container_layout_1: {
      textAlign: "center",
      width: "100%",
      borderBottom: "2px solid black",
      paddingBottom: spacing(2),
      paddingTop: spacing(2),
    },
    link_layout_1: defaultStyle.link,

    // Layout 2 and Layout 4
    container_layout_2_or_4: {
      textAlign: "center",
      width: "fit-content",
      backgroundColor: "#404040",
      padding: spacing(1),
      [below(values.md)]: {
        textAlign: "left",
      },
    },
    link_layout_2_or_4: {
      ...defaultStyle.link,
      "& span": {
        color: "white !important",
      },
    },

    // Layout 3
    container_layout_3: {
      textAlign: "center",
      width: "fit-content",
      backgroundColor: theme.palette.light.grey,
      borderRadius: "8px",
      padding: `${spacing(1)}px ${spacing(2)}px`,
    },
    link_layout_3: defaultStyle.link,

    // Layout 5
    container_layout_5: {
      padding: spacing(1),
      [below(values.md)]: {
        textAlign: "center",
      },
    },
    link_layout_5: {
      ...defaultStyle.link,
      "& span": {
        color: "white !important",
      },
    },
  };
});

export { useStyles };
