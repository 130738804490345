import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    breakpoints: { values, down: below },
    palette,
  } = theme;
  return {
    container: {
      width: "100% !important",
      margin: "0px !important",
    },
    header: {
      maxWidth: "calc(58.33% + 400px) !important",
      [below(values.md)]: {
        maxWidth: "100% !important",
        padding: `0px ${spacing(1)}px !important`,
      },
    },
    ad_container: {
      display: "flex",
      justifyContent: "center",
      padding: `${spacing(5)}px 0px !important`,
      backgroundColor: palette.grayVariation.grayForContainerBackground,
      marginBottom: `${spacing(4)}px !important`,
      "& [class^='ads_container']": {
        marginBottom: "0px !important",
      },
      [below(values.md)]: {
        padding: `${spacing(4)}px 0px !important`,
      },
    },
    article_grid: {
      [below(values.l)]: {
        padding: `0px ${spacing(2)}px !important`,
      },
      [below(values.sm)]: {
        maxWidth: "100% !important",
      },
      "@media print": {
        maxWidth: "100% !important",
        flexBasis: "100% !important",
      },
    },
    sidebar: {
      maxWidth: "400px !important",
      [below(values.l)]: {
        maxWidth: "41.66% !important",
        padding: `0px ${spacing(2)}px`,
      },
      [below(values.sm)]: {
        maxWidth: "100% !important",
      },
    },
    article_preview_grid: {
      width: "100%",
      maxWidth: "calc(58.33% + 400px) !important",
    },
    editor_notes_container: {
      marginBottom: `${theme.spacing(2)}px !important`,
    },
    sponsor_root: {
      paddingInline: theme.spacing(3),
    },
    audio_player_container: {
      paddingBlock: theme.spacing(1),
      paddingRight: theme.spacing(1.25),
      "@media print": {
        display: "none",
      },
    },
    ad_free_top_section: {
      maxWidth: "calc(58.33% + 400px) !important",
    },
    body: {
      [below(values.md)]: {
        padding: `0px ${spacing(2)}px !important`,
      },
    },
  };
});

export { useStyles };
