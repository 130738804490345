import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const {
    spacing,
    palette,
    breakpoints: { values, down: below },
  } = theme;
  return {
    // Shared
    number: {
      fontFamily: "'Bebas Neue', sans-serif",
    },
    section: {
      textTransform: "uppercase",
    },
    section_container: {
      paddingBottom: `${spacing(1)}px !important`,
    },

    // Default
    container_default: {
      alignItems: "center",
      flexWrap: "nowrap !important",
      margin: "0px !important",
      paddingBottom: spacing(1),
      borderBottom: `1px solid ${palette.grayVariation.grayForBorder}`,
      "&.MuiGrid-spacing-xs-3": {
        width: "100% !important",
      },
    },
    media_content_default: {
      maxWidth: `${spacing(25)}px !important`,
      height: spacing(19),
      backgroundColor: palette.grayVariation.grayForBackground,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px !important",
      [below(values.md)]: {
        height: spacing(13),
      },
      "& img": {
        maxHeight: "100%",
        cursor: "pointer",
      },
    },

    // Layout 2
    container_layout_2: {
      margin: "0px !important",
      width: "100% !important",
      justifyContent: "space-between",
      paddingBottom: spacing(1),
      borderBottom: `1px solid ${palette.grayVariation.grayForBorder}`,
      [below(1025)]: {
        alignItems: "center",
      },
    },
    media_content_layout_2: {
      backgroundColor: palette.grayVariation.grayForBackground,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px !important",
      height: spacing(13),
      [below(1025)]: {
        height: spacing(23),
      },
      [below(values.sm)]: {
        height: spacing(13),
      },
      "& img": {
        maxHeight: "100%",
        cursor: "pointer",
      },
    },

    // Layout 4
    container_layout_4: {
      margin: "0px !important",
      width: "100% !important",
      justifyContent: "space-between",
      paddingBottom: spacing(1),
      borderBottom: `1px solid ${palette.grayVariation.grayForBorder}`,
      [below(1025)]: {
        alignItems: "center",
      },
    },
    media_content_layout_4: {
      backgroundColor: palette.grayVariation.grayForBackground,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px !important",
      height: spacing(13),
      [below(1025)]: {
        height: spacing(23),
      },
      [below(values.sm)]: {
        height: spacing(13),
      },
      "& img": {
        maxHeight: "100%",
        cursor: "pointer",
      },
    },
  };
});

export { useStyles };
