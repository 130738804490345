import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { screenWidthIsLowerThan } from "../../../../hooks/useMediaQuery";
import clsx from "clsx";
import { useStyles } from "./Layout1.style";
import ArticleBody from "../../ArticleBody";
import ArticlesCarousel from "../../../../common/Carousel/Articles/ArticlesCarousel";
import AudioPlayer from "../../../AudioPlayer/AudioPlayer";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import ByLine from "../../ByLine/ByLine";
import Disclaimer from "../../Disclaimer/Disclaimer";
import EditorNotes from "../Components/EditorNotes/EditorNotes";
import Grid from "../../../../common/Grid/Grid";
import labels from "../../../../config/labels";
import MostRead from "../../MostRead/MostRead";
import PromotionalFooter from "../Components/PromotionalFooter/PromotionalFooter";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import RelatedNews from "../../RelatedNews/RelatedNews";
import SectionTag from "../../SectionTag/SectionTag";
import Sponsor from "../../../Sponsor/Sponsor";
import StickySocialNetworkShareButtons from "../../../StickySocialNetworkShareButtons/StickySocialNetworkShareButtons";
import TopAds from "../Components/TopAds/TopAds";
import Typography from "../../../../common/Typography/Typography";
import values from "../../../../common/Theme/themes/breakpoints/values";
import withConfig from "../../../../utils/withConfig";
import Edit from "../../../Actions/Edit";
import Leaderboard from "../Components/Leaderboard/Leaderboard";
import { HEADER_CONTAINER_SELECTOR } from "../../../../utils/constants/trinityAudioConstants";

const Layout1 = ({ article, props, sponsor }) => {
  const classes = useStyles();

  const {
    article_body,
    category_name,
    subscribe_to_content,
    is_article_preview,
    is_town,
    is_premium,
    ad_targets,
    article_disclaimer,
    disclaimer_policy_page_links,
    town_editor_email,
    section_name: section,
    section_link,
    video_ad_content,
    id,
    section_id,
    column_id,
    town_id,
    category_id,
    bullseye_ad_count,
    breadcrumbs,
    can_edit,
    edit_url,
  } = props || {};

  const {
    name,
    url_content: url,
    subheading,
    column_for_front_end: column,
    opinions: article_column_category,
    is_advertisement,
    ad_free,
    submitted_via,
    has_audio_player,
    byline,
    humanized_published_at: published_at,
    humanized_updated_at: important_data_changed_at,
    get_town_admin_profile_path: town_admins_profile_page_path,
    media_content,
    promotional_footer,
    disclaimer,
    section_for_front_end,
    category_for_front_end,
    column_for_front_end,
  } = article;

  const largeScreen = screenWidthIsLowerThan(1025);
  const isMobile = screenWidthIsLowerThan(values.sm);
  const mediumScreen = screenWidthIsLowerThan(values.l);
  const leaderboardProps = useMemo(() => {
    if (isMobile) {
      return {
        top: { ad_unit: "leaderboard_mobile", size: [320, 100] },
        body: { ad_unit: "leaderboard_mobile_1", size: [320, 100] },
      };
    }
    return {
      top: { ad_unit: "tap_leaderboard_001", size: [728, 90] },
      body: { ad_unit: "tap_leaderboard_002", size: [728, 90] },
    };
  }, [isMobile]);

  const SponsoredTag = () => {
    if (is_advertisement) {
      return (
        <div className={classes.sponsored_content}>
          <Typography level="paragraph_2" color={"gray"}>
            {labels.SPONSORED_CONTENT}
          </Typography>
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        spacing={mediumScreen ? 0 : 3}
        classes={{
          root: clsx(classes.container, ad_free && classes.ad_free_container),
        }}
      >
        {!ad_free && (
          <Leaderboard
            props={{ ...leaderboardProps.top, targets: ad_targets }}
          />
        )}

        <Grid
          container
          justifyContent={"center"}
          item
          xs={12}
          md={10}
          className={classes.header_container}
        >
          {breadcrumbs?.length > 0 && (
            <Grid
              item
              xs={11}
              md={12}
              classes={{ root: classes.breadcrumbs_container }}
            >
              <Breadcrumbs items={breadcrumbs} />
            </Grid>
          )}

          {can_edit && (
            <Grid item xs={12} classes={{ root: classes.edit_container }}>
              <Edit editUrl={edit_url} />
            </Grid>
          )}

          <SponsoredTag />

          {sponsor && (
            <Grid item xs={12} classes={{ root: classes.sponsor_container }}>
              <Sponsor sponsor_info={sponsor} />
            </Grid>
          )}
          {(section_for_front_end?.name ||
            category_for_front_end?.name ||
            column_for_front_end?.name) && (
            <Grid item xs={12} classes={{ root: classes.tag_container }}>
              <SectionTag
                variant="layout_1"
                label={
                  section_for_front_end?.name ||
                  category_for_front_end?.name ||
                  column_for_front_end?.name
                }
                url={
                  section_for_front_end?.path ||
                  category_for_front_end?.path ||
                  column_for_front_end?.path
                }
              />
            </Grid>
          )}
          <Typography
            level="h1_header_1"
            color={"black"}
            className={clsx(classes.header, HEADER_CONTAINER_SELECTOR)}
          >
            {name}
          </Typography>

          {subheading && (
            <Grid item xs={12} className={classes.subheader_container}>
              <Typography level={"h3_sub_header"} className={classes.subheader}>
                {subheading}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={largeScreen ? 12 : 10}
          classes={{
            root: is_article_preview
              ? classes.article_preview_grid
              : classes.article_grid,
          }}
        >
          <Grid
            container
            justifyContent="center"
            classes={{ root: classes.article_container }}
          >
            {media_content && (
              <Grid
                item
                xs={12}
                classes={{
                  root: ad_free
                    ? classes.carousel_container
                    : classes.carousel_container_with_ad,
                }}
              >
                <ArticlesCarousel
                  items={media_content}
                  adFreeArticle={ad_free}
                  customClasses={{ caption_credits: classes.captions_credits }}
                />
                {!ad_free && !largeScreen && (
                  <TopAds
                    video_ad_content={video_ad_content}
                    bullseye_ad_count={bullseye_ad_count}
                    ad_targets={ad_targets}
                  />
                )}
              </Grid>
            )}
            {!ad_free && largeScreen && (
              <TopAds
                video_ad_content={video_ad_content}
                bullseye_ad_count={bullseye_ad_count}
                ad_targets={ad_targets}
              />
            )}
          </Grid>
        </Grid>
        {!ad_free && (
          <Leaderboard
            props={{ ...leaderboardProps.body, targets: ad_targets }}
            options={{ marginBottom: false }}
          />
        )}
        <Grid
          item
          xs={12}
          md={mediumScreen ? 12 : 10}
          classes={{
            root: is_article_preview
              ? classes.article_preview_grid
              : classes.article_grid,
          }}
        >
          <Grid
            container
            justifyContent="center"
            classes={{ root: classes.article_container }}
          >
            {has_audio_player && (
              <Grid item xs={11} className={classes.audio_player_container}>
                <AudioPlayer />
              </Grid>
            )}

            <Grid item xs={12} className={classes.article_body_container}>
              <ByLine
                author={byline}
                published_at={published_at}
                updated_at={important_data_changed_at}
                author_email={town_editor_email}
                town_admins_profile_page_path={town_admins_profile_page_path}
                title={name}
                url={url}
              />
            </Grid>

            {!is_article_preview && (
              <StickySocialNetworkShareButtons top={window.innerHeight / 3} />
            )}

            {article_body && (
              <Grid item xs={12} className={classes.article_body_container}>
                <GoogleReCaptchaProvider
                  useRecaptchaNet
                  reCaptchaKey={withConfig("RECAPTCHA_KEY")}
                  scriptProps={{
                    async: true,
                    defer: true,
                    appendTo: "body",
                  }}
                >
                  <ArticleBody
                    text={article_body}
                    column={column}
                    isAdvertisement={is_advertisement}
                    articleColumnCategory={article_column_category}
                    isAdFree={ad_free}
                    categoryName={category_name}
                    subscribeToContent={subscribe_to_content}
                    isArticlePreview={is_article_preview}
                    isTown={is_town}
                    isPremium={is_premium}
                    adTargets={ad_targets}
                  />
                </GoogleReCaptchaProvider>
              </Grid>
            )}

            {promotional_footer && (
              <Grid item xs={12} className={classes.article_body_container}>
                <PromotionalFooter promotionalFooter={promotional_footer} />
              </Grid>
            )}

            {disclaimer && (
              <Grid item xs={12} className={classes.article_body_container}>
                <EditorNotes editorNotes={disclaimer} />
              </Grid>
            )}

            <Grid item xs={12} className={classes.article_body_container}>
              <Disclaimer
                categoryName={category_name}
                column={column}
                isAdvertisement={is_advertisement}
                articleColumnCategory={article_column_category}
                submittedVia={submitted_via}
                disclaimerPolicyPageLinks={disclaimer_policy_page_links}
                customDisclaimer={article_disclaimer}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!is_article_preview && (
        <Grid item xs={12} classes={{ root: classes.most_popular_container }}>
          <MostRead
            variant={"layout_1"}
            articleProps={{
              id,
              section_id,
              column_id,
              town_id,
              category_id,
            }}
          />
        </Grid>
      )}

      {!is_article_preview && (
        <Grid item xs={12}>
          <RelatedNews
            articleProps={{
              id,
              section_id,
              column_id,
              town_id,
              category_id,
            }}
            variant={"layout_1"}
          />
        </Grid>
      )}
    </>
  );
};

Layout1.propTypes = {
  article: PropTypes.object.isRequired,
  props: PropTypes.object.isRequired,
};

export default Layout1;
